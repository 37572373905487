import { NeufsneufModal } from '../../js/components/common/neufsneuf-modal'
import { TagCommanderManager } from '../../js/services/tag-commander-manager'
import { BOUTON_TELECHARGEMENT_PRM, BOUTON_TELECHARGEMENT_PRM_ERROR_TECHNIQUE, FERMETURE_MODAL_CONDITIONS_GENERALES } from '../../js/tag-plan'
import { ModalTypesEcoWattFeatureFlipping } from '../../js/services/enums'
import { mesPrmsEntrepriseService } from '../../js/api-client'
import { StoreManager } from '../../js/services/store-manager'
import { HabilitationsManager } from '../../js/services/habilitations-manager'
import { modalConfirmShowTasks, multiSelectModalEcowattDemandePrms } from './main'
import { TacheManager } from '../../js/services/tache-manager'

export default class ModalEcowattOrangeRouge extends NeufsneufModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor (typeModalEcoWattFeatureFlipping) {
    super(typeModalEcoWattFeatureFlipping)

    if (typeModalEcoWattFeatureFlipping === ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_OFF) {
      this.onAccept(() => {
        TagCommanderManager.tagClick(FERMETURE_MODAL_CONDITIONS_GENERALES)
      })
    } else if (typeModalEcoWattFeatureFlipping === ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_ON) {
      this._initModal()
    }
  }

  _initModal () {
    this.generateCustomButtonsModalFeatureFlippingOn()
    const handleModalActions = () => {
      multiSelectModalEcowattDemandePrms.removeError();
      if (HabilitationsManager.getSirensModelTitulaire().length > 1) {
        multiSelectModalEcowattDemandePrms.empty()
      }
      window.addEventListener('load', () => this.initialiseSirenDropDownMenu());
      const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("neufsneuf-toast");
      toastError.hide();
    };
    this.onAccept(handleModalActions);
    this.onDismiss(handleModalActions);
  }

  /**
   * Demande d'export des prms concernés par un délestage
   */
  requestExportPrmsDelestes(){
    multiSelectModalEcowattDemandePrms.removeError();
    const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("neufsneuf-toast");
    toastError.hide();
    if (multiSelectModalEcowattDemandePrms.isOptionsPartiallySelected() || multiSelectModalEcowattDemandePrms.isAllOptionsSelected()) {
      const downloadPrmButton = document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName('download-button').item(0)
      downloadPrmButton.setAttribute('loading', true)

      mesPrmsEntrepriseService
        .exportPrmsDelestes(StoreManager.cnAlex,multiSelectModalEcowattDemandePrms.getSelectedValues(), StoreManager.bandeauMeteoDateSelectionneeFormatee)
        .then(() => {
          this.hideSuccess();
          multiSelectModalEcowattDemandePrms.removeError();
          TacheManager.startInterval();
          modalConfirmShowTasks.show();
        })
        .catch((e) => {
          // add tag lors de cliquer sur "Télécharger la liste" et obtenir une erreur technique
          TagCommanderManager.tagPage(BOUTON_TELECHARGEMENT_PRM_ERROR_TECHNIQUE);
          toastError.setToast("Echec du téléchargement", "true", "0", "M", "error_outline", 'blanc', 'rouge-500', 'Error');
          toastError.show();
          document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName("lnc-modal-content").item(0).scrollTop = 0;
        })
        .finally((e) => {
          downloadPrmButton.removeAttribute('loading')
        })
    } else {
      multiSelectModalEcowattDemandePrms.setErrorMessage('Veuillez sélectionner au moins un SIREN')
      document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').setAttribute('is-modal-error', true)
    }
  }

  /**
   * Génération des boutons customiser pour la modal ecowatt
   */
  generateCustomButtonsModalFeatureFlippingOn () {
    window.addEventListener("load", () => {
      document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").getElementsByClassName("download-button").item(0)?.addEventListener('click', (event) => {
        //add tag bouton_telechargement_liste
        TagCommanderManager.tagClick(BOUTON_TELECHARGEMENT_PRM);
        this.requestExportPrmsDelestes();
      });
    })
  }

  /**
   * Fonction initialisant les options du dropdown menu à partir des habilitations
   */
  initialiseSirenDropDownMenu () {
    const habilitationsTitulaire = HabilitationsManager.getSirensModelTitulaire()
    const componentMultiSelect = document.getElementById('siren-lnc-multi-select-demande-prms')
    let habilitationsJson = '[]'
    if (habilitationsTitulaire.length > 0) {
      // Crée un JSON pour les options du multiselect
      habilitationsJson = JSON.stringify(habilitationsTitulaire.map(({ siren, raisonSociale }) => ({
        label: `${raisonSociale}\n${siren}`,
        value: siren,
        ariaLabel: raisonSociale
      })))

      componentMultiSelect.setAttribute('data', habilitationsJson)

      // Si il y a un seul élément et que StoreManager a des habilitations
      if (habilitationsTitulaire.length === 1) {
        if (habilitationsTitulaire[0].autorisations.length >= 1) {
          componentMultiSelect.setAttribute('disabled', 'true')
        } else {
          componentMultiSelect.hidden = true
        }
      } else {
        // Appliquer les attributs pour tous les cas où la liste des habilitations existe
        componentMultiSelect.removeAttribute('disabled')
        componentMultiSelect.hidden = false
      }
    } else {
      componentMultiSelect.hidden = true
    }
    componentMultiSelect.setAttribute('selected-value', habilitationsTitulaire.length === 1 ? habilitationsTitulaire[0].siren : null)
    componentMultiSelect.setAttribute('tooltip', 'Seuls les SIREN titulaires sont disponibles pour cette fonctionnalité.')
  }

  /**
   * Ouverture de la modal ecowatt
   */
  show () {
    super.show()
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement accept
   */
  hideSuccess () {
    super.hideOnAccept()
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement dismiss
   */
  hideDismiss () {
    super.hideOnDismiss()
  }
}
